import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../CategoryBar.module.css';

const IconServing = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <rect x="0.039978" width="33.92" height="32" fill="url(#pattern0_2201_23657)"/>
      <defs>
        <pattern id="pattern0_2201_23657" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2201_23657" transform="matrix(0.0188679 0 0 0.02 0.0283019 0)"/>
        </pattern>
        <image id="image0_2201_23657" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE8klEQVR4nO1YWYgdRRStuERFjRgFFaO4IrhgZHCN8hBmuuuc6jcMmEb8i39uCH4I4ocDwvhlkskLIqgR/VHyoyCKkBhF4wLBRCGJS1DjvoAmxkRnohOf3HhbKj2vlzev39gJc6Hopm7VrXvqLnWrjJmjw4TCMLwMwEqS20ju07ZN+oRn6k5xHM8nuRrAFMl2pyY8AC0Za+pIcRzPB7BeFZ4gOe6cu2ZoaOhEaSSvBbCK5KQCWl9LMCRXK4ivAVyRNc45dyXJbxRMy9QwJqbEEnkgUmAmZY619lJTF8K/gS07vLKLOS2ds6IiHV7PikttGwuFkNwug621V5ddWGJGgWztFYTKaxe1MkL2ysBGo3FS2YVlrAL5rVcQqkM7S9nSQESZboFYaxfoAntMXYBQXUvSbdmFrbXX9cO1TA6vMF68YF81g3S9vAf9pylrcniF8eKl30lJrSUWXQxgf5XplyVdq9DNknQqh10eGAXxrY4drwJEpUAGBgaO9UoUOexaEgeSAKRFUXS91mH7kxJF5vSo/zwAH5ZJvezmbNF6q5VXNJL8SyxRAQgzOjp6FMktMwUC4K3cBTRmVkhG0jNmr/4vr1VJUoOrwic9uFO7VLkyG0AAfNwLEKRdiuRdAJ4Xhu6S3ATXFSkjga7pdxeAzwG8S/JJAHcEQbDQzDaR/LMT4iAILsqaI7yC3fohDMPzMtZb16NLtTu6maRUALdaa28UBQGsVeayHPDLVOEXZPettReSvNlaeyfJTTp/g6TW9FwB2Qcgv05TUlxDmWtygKzRMfd2sNZCkt8r0Ls7yH9Oee9baxeZGVIQBOcA2Kyy1k4b4Jy7XJk7soQIT8ZYa6/K4EcKdF/aRVWBH6uyBoCfO26IHlC/6MAz03zpS8wZx/HRWWBJPp1kF5Hp88IwPIvkEyR/6gHEHrFErlVJvqQ7vjTNkz5V8NVMAcaYwcHBU+QRQxe9L82Pouhckp+prA+iKDo9tc5xzrlBPZS/9CywG8BQ3to+kPuzSnp9DhLeg0VyZEGSfwP4IwzDS5L+ZrN5Psmdqtwm59ypHoBFAJ6RHU9nQpKPimuasqTFoUze3EG5gwHmnLupjCySj6sy74krOucuTiwF4B2xXDLWOXdByt2kBhsjeUPaPbs5eX/Xu8aCpF/+kztLo9E4vowsqZoBfKGKPwbgO/1/w79Wi1Wgp70ctpXVc7KQ7nyY9JG0HcuDYllLABzw3OQQEFJFk3xNeVt9K/VMJB/Whce8vjHteyRrngAXwB3kjauiL6esOY/ks96F7mxTJVlrA134zaRP/rUPWfPUJQ8452K/v9FoHCPVg3xTAB9SmfKKs9hUTcPDwyfrBWpCUqE0fU6dyjO9F6gTRQmB5G2a1SQWafpFXgmwRHYzySQFyh1SA2W9I8uLplhP5d9j+knQJyKSD2grfC7yQLyo351ykvtjpDJO0uysvOYDuEWVeUVbO+37WUDiOD5BzonkPBJXFb589cp8UG5emVMZNZvNM9SHpSzYnVV/dQIi/yMjI6d5V9oNMleyloL7yD+j+k4kP/Xy/44S4/8DkrgRgK9S5cYuOeHNbBKApzwlMu8oWUCS2kqKTH2NebsvabaIANzuAcm8NeYBqQUF3v1crrSHLRAhyS4SpKYE1RpINzQHpG7E/8u1SG6s6kXDb0cEEHR58ZqjI5H+AQahsi5+aMnUAAAAAElFTkSuQmCC"/>
      </defs>
    </svg>
  );
};

IconServing.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconServing.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconServing;
