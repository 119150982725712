import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../CategoryBar.module.css';

const IconFavors = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <rect width="32" height="32" fill="url(#pattern0_2201_23668)"/>
      <defs>
        <pattern id="pattern0_2201_23668" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2201_23668" transform="scale(0.02)"/>
        </pattern>
        <image id="image0_2201_23668" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEzUlEQVR4nNVZTWhcVRS+8RfxD7Ras3Aj1l8oyqgUix2tmffu9903cVzMRkGzKBalIAiiojJWqhjBhVAQuwhEd91pwS7Soht/qjYubCmtdlPBSFE02kIVbeRMzk0vk/dmJsncyfTAJZn3zjvfOfec79177jMmglhrryP5Oslpkqd0TAPYnmXZGnM+iHPuUZKzJOcKxh8AambQgwDwnzr8sXPuwUqlcrkMkg+R3CP3RGdgg7Hz5dTMBICXivRIvqw6vw9kmXGeE81MdKHrM/OaGTQB8J04J+XUhe5mDXraDJqQ/EucK5fLV3TSHR0dvVIz8qdZLSG5qc0bqVdjU/RArLWPxw4EwGPRAyH5ooK91XK96cQS7CzSJzmuPHuhlz4XObBTA9nmr1lrH/aOyf+dbBTpA9im13ea2CKvVwV7JHDgeFAaP3ayUaQPoKaT9FHMGDzYQU1/yV8j+VPg2IlONor0nXMlDeRgzBi8EycFrFqtrvXXAECdO0HSdrJRpF+tVtdqcCdjxmDq9fplJM+SPGOMGYoAMSS2BUOwTCwBcEu3PFgBxnEt3XWxMBbeNgA+jYVB8jPF2GwigowpyAexMAB8qBl50kQEeVVL641YGCTfVIxXTESQXbqIPR0R4xnN+vsxM7JXQbJYGNbaqmbkExNxtg4pyF2xMKy1d+tkfR8zkGYrW6vVro2FkWXZGn9IEQUgaIZOm8gC4LRy8aqeG0/T9E6dqaMmsgA4poHc0XPjsifSQPb13PhirP26lqQ9MSibOL85BLBFA5k0kYXkpJbxFs+bSqVy/XIM3U5yAsDfJH8rl8sXyXGnGt9hIguAHYq1vdFoXCC7YfVloqtyA7BRmhp/Wqh/d2lwE5qRrbEDIblVsSb093vBCabsvvc45x5ofW5IFiEAU0Gzc0b2U1mW3RYY36cEZB8CcTqRU/6ac+4mAO/6N5pvwAA8Ua/XL5SHvgkCkKapkXeUCeCIBrI+diDW2vXq6JGCdabhGzzV+2rhNEPH/jALoeingbk+j1N5voiP/s22cBoT/PCfAv4h+c7IyMjV/sEkSa5ZhSDmZAi290N8Et/Ux9Dnc4HItkNr0BP9VwDPNutv9WVIuADgF0944TDJGxYFIoSXJ5xz9wE4EMzK19baDasVgbV2g/gQ8OGA+NiyU54TEn8RKO1N0/RWPQQYAzATvPIm0zQd7lcAaZoO68J4Vn0TX8bEN/HRtxR673NTKpUulhKS3abniJSY1KN8aZJvGHqy0SSf/LbWXhorgNI5f2ZDf2QD2ZU/7TiSJMnNAHYH5faDc67e6yCccyMkDwczPaUruefITCtH2hkrSapaFp2NAdChEEh2xT0IYJ3/isV5u8d8Fwrgnpby/5bk/d3aHpIZ15NAPwO7kyS5sagUl9M7tCuVNE2HpW8PKmTGOfeU7L1ML4FySnEpQHkT1SyVdhwxK5U8jvjU55Ri29S303fFHOmt5HHEk7Fohv2zQQb/bc1g60Qh4Eg0qdfrl5B8PvimLn3CuPT0kn4Ab+u15gECgOdkeE7JPdERXT0HGA/0Z8W2YJh+SStHSP5cNMPLyWDfJWdL86W19l65J72L1vxh38fIPdHJ23IMgqyII2bQxC6RI2bQJenMkfNLbA5HYsn/HN+eA5YmpskAAAAASUVORK5CYII="/>
      </defs>
    </svg>
  );
};

IconFavors.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconFavors.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconFavors;
