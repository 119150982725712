import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../CategoryBar.module.css';

const IconCatering = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <rect width="32" height="32" fill="url(#pattern0_2211_196)"/>
      <defs>
        <pattern id="pattern0_2211_196" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2211_196" transform="scale(0.02)"/>
        </pattern>
        <image id="image0_2211_196" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFOElEQVR4nNVaa6hVRRTe9n7Ym95R9ACTXtSFCItOxj3nzLdmn3Nvj2NYRv0QAwnCjMIKjhZFl1SIqB8FQn80jEohiOwhFBYRpUGGPSWqm2J2y6xres1Yx2/H3HH2Pve1t5wFm3vWmv3NfN+evWdmzdwoGqEBeE9E9jnXBxoXkTWhOIB1obhT104R+QLA0jiOL46KMhlOqnWNJZ5StgfAU41G49DChIz3t+sDuE1EXhGRf+mvbDabh3SckMQ3xgiAAYpZ1LFC1Ky13QD2isjuarV6SccKYfx5lr3U0UKMMedoj+ilvztWiBqA5Sx/JOpkIcYY4Ue/rqOFxHF8jIgMAhiqVCond6wQNRF5l70SRxNtbuMA/tHfOkwyvisrPgYhiynk4byFvJ34vNZkxccg5G4KWZ6rEBE5j4vFP0XkrTiOz20T/x+rSxCS3JvWljGmlNsH3+4pjhRrjDnSfe1CBuAyCvl+PJzTKm+thYwxx48Gp/eT1IAzKqn/VxrGWns2xf4RTbQB2EAhl48Gp/eT+HpXWBbJRqNxNO/ZE020AXiGlT85StzjxD2rvs4N9LenYXp6ek6k+L+jiTYRmaqTlE5WcRxfNdLe0FdIP2xr7aUaK5fLp1HI1gzclRTydZSHJU8XwG8iMj3rXhGZBmAbSS9J4vV6/SzW8XNGO4uIezHKwzQV1SV28v4CuD9FxNxkcgTwWqPROCIp0yGZ8R9C2PL+Htuq91hrr45ytEki0mQSpILmeiLuYVzT175SqXSYW26tvYBCvvMr7urqOlznH5a/macIl/AMNviLGwewhUJmhHDVanUKy7/yexvACtb5qw7BeWsYNkT6I4v6GtfyNh/yeu+VfZkCdxpjrilCg5vNKaGf3Lj6nG+CGZ6IXE/c+4GhfReAclSkWWuvIKENHtHPsyZOXZoT9wbruYH+kDHm1qL4u4Snk8Baj+haPt3g8CwiM4lbQf8d3v9odDBMnx4JverG1WePBJ+utXYOib+gPlfK6p9RFPc0QsMmLfU5B8wJ4UTkAXeSTBaj1Wr1zOhgGIAFJNTnEe1jTy0I4UTkMeKa9FvzhrX2oaK4p6WjD7px9Ul0cQgH4Dni7qXfm6y9yuXysVHRJiLLSGi2R3Q2iS1LwSXzxUyGJgH4lLF5UdEGYDVfiZvcuPoUuDoF18rrjTGVJCYiPcn6q1HE0YJrenBDQqVQvu0e7Hi4z/gAupIY8/hvKebGIvi7hDaSUCvPSEx9CtmYgmsRrlQqF6WkCEvz5u4T2qwN12q18924+hSyOQXXHxpudWkiGT2ZmyWr3FqtdrobV59PdksIJyK/a3l3d/cJoQ0HZCRcuRiAHdpwvV4/zo2321zQhEzLNfdw46VS6SjiBvPmHiTkJ05MjpTQ7hAuScj8s0Iu5ffp4jFv7j6hIeYdw4ZLFZZFKEmBdaMuZQtoMG/u0UgSqFKpNDlrA47n69qTk1M28nbkzT340VprT3Ljvb29pyQ7LSm47eyRU1MGiW15c/cJ/Rgafo0xF2btkojINxx+p3jxqaFcPncTkU/Y8DQ3boy5jkI+TsF9xPJrU3AfRkUagFVs+HaP0B0U+HoIx/90OAAnIrMYXxkVaQAWsuGnPUJL3HwjbRdRlyQe7gnGF0ZFmrW2GlpTAfiShMptTm2HvULqy/76TFSkcb7oT5IkHU71L8n0+xNlYjp/ML3Vncib9bwEwC3qAxjw55dCTETuJHH/mtUGNy+EA3BfcewPJKUHl5u4ZNlkrb1rBDDNCufr0ZouWfh3/niI/AfNpIVq07X8NwAAAABJRU5ErkJggg=="/>
      </defs>
    </svg>
  );
};

IconCatering.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconCatering.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconCatering;
