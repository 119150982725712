import React, { useEffect, useState } from 'react'
import { FieldArray } from 'react-final-form-arrays'
import { bool, func, object, shape, string } from 'prop-types';
import css from './FieldCheckboxGroupWithQuantity.module.css';
import { FieldNumberInput, FieldCheckbox, FieldQuantityInput } from '../../components';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';


const FieldCheckboxGroupWithQuantity = (props) => {

  const {
    arrayName,
    options,
    optionLabelClassName,
    rootClassName,
    quantityClassName,
    maxOptionsMaybe,
    className,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return   (
    <div className={classes}>
      <FieldArray name={arrayName}>
        {(filedsProps) => {
          const { fields } = filedsProps;

          const textClassName = optionLabelClassName;
          const textClassNameMaybe = textClassName ? { textClassName } : {};


          return options.map((name, index) => (
            <div key={options[index]?.key} className={css.item}>
              <FieldCheckbox
                id={`${arrayName}.${[index]}.option`}
                name={`${arrayName}.${[index]}.option`}
                label={options[index].label}
                value={options[index].key}
                {...textClassNameMaybe}
              />

              {/*{fields?.value && fields?.value[index] && fields?.value[index]?.option && !isEmpty(fields?.value[index]?.option) && <FieldQuantityInput*/}
              {/*  id={`${arrayName}.${[index]}.quantity`}*/}
              {/*  name={`${arrayName}.${[index]}.quantity`}*/}
              {/*  type='number'*/}
              {/*  min={1}*/}
              {/*  max={maxOptionsMaybe ? maxOptionsMaybe : null}*/}
              {/*  initialValue={fields.value[index].quantity || 1}*/}
              {/*  className={quantityClassName}*/}
              {/*/>*/}
              {/*}*/}
            </div>
          ))
        }
        }
      </FieldArray>
    </div>
  )
}


FieldCheckboxGroupWithQuantity.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
};

FieldCheckboxGroupWithQuantity.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  // Error message that can be manually passed to input field,
  // overrides default validation message
  customErrorText: string,

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id: string,
  label: string,

  // Uncontrolled input uses defaultValue prop, but doesn't pass value from form to the field.
  // https://reactjs.org/docs/uncontrolled-components.html#default-values
  isUncontrolled: bool,
  // a ref object passed for input element.
  inputRef: object,
};


export default FieldCheckboxGroupWithQuantity;
