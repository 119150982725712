import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../CategoryBar.module.css';

const IconRentals = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <rect x="0.679993" width="32.64" height="32" fill="url(#pattern0_2201_23660)"/>
      <defs>
        <pattern id="pattern0_2201_23660" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2201_23660" transform="matrix(0.0196078 0 0 0.02 0.00980391 0)"/>
        </pattern>
        <image id="image0_2201_23660" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADm0lEQVR4nO1ZSWgVQRAd1wQVPbiBXkS8KF5MLgrKgP5J6lX3DxH5F0VjvLgcRdwuOYkrxouoIOJFvSkiIrggKgpuCJqACC5RRFFxweVgopHSammH+cn8GJMfmQcNP93V1e9NdfWWIMiQIUOGDP8boigaCeAqgNvyOxigGATgGDN3SgFwVOqCgQYA25wIT0xzMIAwiJm3eAI+aXF/byn7yBQKhSEA9seisAPAzljd4erq6mFBOaK+vn4sgDMxwp/z+fxEKQC+xNrOSJ+gnGCtnQOgTQm+dlNJouFsvKh8VBtpbyOi2f3LPgiCMAyHAljPzF+V2DUAm/X3SyIa7WxzudwYqdO2TWKrwqTvOpmWQX/AGFMt+4OS+Q5gNxGNd2SJaEW8j9SpkBfW2nHMvEf6qo+bRDSrzwSEYThKSAPoUFIPAcyXNmY+pKRuNDU1DY73lTpp034Hpc4YkxMf2q+dmXf9082zUCgMZ+Y1AJ4rkQ4RZK0dIe0AFrkEZ+bpxfxIm9p0GmMWeieAZvdxmPkZgFW9urJJHsiUAPDEW3XOW2urnI0xZjKAN9q2sjufYuMWhrq6ukmu3lpbxcwXvJXtkTGm4a/yJwzDSmZeDuCBJ+AuAPh2+jVv6cDH0/pn5hNuGrqoOsgYAO55495n5qVEVBGkhTFmKjNvd0ukllYAy+LzXjfAk2rzuJR9QWylj/Y9keTbGNMgY3s8XgHYWltbOyXRqTghImbm0wC+eaG9BMAmHSk0cfep3duu8qIYiGgGgHfqY2+Ro4scPq1w8XgJx1MStj8+AICLsQPeB0nsYqGUxJfTrDtLGWPmlSrCExN6Z7Ej4ruIXQURrWbm97ETwvXfRt5aHi+yHLYIaWPMBvkCNTU10wCccyKESE9FFBFzVseAjikfrEW5JHHs9IX8rNDptVHvEa1dde6H0q65Ktw2KtdkIUnhlCURQKOs87Ls9iHx87q3NAqHpGmeWkgSSrHtCfhvuGRC/gE4i0jQe1NLnn5KSOYrZRsRLnFlKnshQR/4EGRCBFlEPGRTK22OyAsgES2JomhC6s4p23rLRxRFE4SjcE0S8vue7D3v3JGbIhEtkGtvfwkJw7BSOOit9U7ClePCHw6MMTMBrJWnTPfK4UXqs9av7SshSMFFOKd5OZkr/xqQhwX/+tvTjS6JbNqCX29nB4go78+OkhGbl8+6GfRyCiFXuiH/VMYCsLirfM2QIUOGDBmCXsIPNvYlACIkTwcAAAAASUVORK5CYII="/>
      </defs>
    </svg>
  );
};

IconRentals.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconRentals.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconRentals;
