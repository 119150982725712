import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../CategoryBar.module.css';

const IconVideography = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <rect width="32" height="32" fill="url(#pattern0_2201_23662)"/>
      <defs>
        <pattern id="pattern0_2201_23662" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2201_23662" transform="scale(0.02)"/>
        </pattern>
        <image id="image0_2201_23662" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABE0lEQVR4nO2WPU7DQBBGpwHugJA4hC+QBmnfZ8mdz0FLmbOkpE1BiUTgBFBxjAhqEBJGzo+CRIyzs5knjaydYr1P39q7ZkEQ/ArwJOnDaS1+RDJYzF5l6yL9lLb1cusrRMxJInJaVqyIOUMhkhmKRDJDkcgxJQLcAc/AlXkW0ephNZd0OcS8hxR56z1v2rY9HWL+0UWapjkHZt0YeJWUhnjHqCLduK7riaSX/9huGlPki6qqToBrYPmdzjswTSmduRLpSCldALe9dB5tDxQix7y1au8fe1PK71elHIjyfkUB7ou4NI6JQiQzFIlkhiIRL4nIaVmxIrYrtgz7ChFzkoiclvVEFodezF8LeFhPLwgC2+ATFnr6BuhBH1kAAAAASUVORK5CYII="/>
      </defs>
    </svg>
  );
};

IconVideography.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconVideography.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconVideography;
