import React, { useState } from 'react';
import { string, number } from 'prop-types';
import { FieldNumberInput } from '../../components';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import css from './FieldQuantityInput.module.css';

const FieldQuantityInput = (props) => {
  const {
    min,
    max,
    rootClassName,
    className,
    quantityClassName,
    ...rest
  } = props;

  const handleIncrement = input => {
    const newValue = parseFloat(input.value) + 1;
    if(max !== null) {
      if(newValue <= max) input.onChange(newValue);
    } else {
      input.onChange(newValue);
    }
  };

  const handleDecrement = input => {
    const newValue = parseFloat(input.value) - 1;
    if (newValue >= min) {
      input.onChange(newValue);
    }
  };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <Field
      {...rest}
      render={({ input, meta }) => (
        <div className={classes}>
          <div className={css.inputGroup}>
            <button
              type="button"
              className={css.decrementButton}
              onClick={() => handleDecrement(input)}
            >
              -
            </button>

            <FieldNumberInput
              {...props}
              input={input}
              meta={meta}
              className={css.input}
              max={max}
            />

            <button
              type="button"
              className={css.incrementButton}
              onClick={() => handleIncrement(input)}
            >
              +
            </button>
          </div>
        </div>
      )}
    />
  );
};

FieldQuantityInput.defaultProps = {
  id: null,
  name: null,
  min: null,
  max: null,
  initialValue: null,
  rootClassName: null,
  className: null,
};

FieldQuantityInput.propTypes = {
  id: string,
  name: string,
  min: number,
  max: number,
  initialValue: number,
  rootClassName: string,
  className: string,
};

export default FieldQuantityInput;
