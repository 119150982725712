import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../CategoryBar.module.css';

const IconEventPlanning = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <rect width="32" height="32" fill="url(#pattern0_2201_23654)"/>
      <defs>
        <pattern id="pattern0_2201_23654" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2201_23654" transform="scale(0.02)"/>
        </pattern>
        <image id="image0_2201_23654" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABeElEQVR4nO1ZQU7DMBD0qSd+AAi+ARxySzKTXP2kUHhBXwAfgDMH/BEkWg58AmTJkaoqIYntJqTZkfaQqvHueNeerFapIyHLsmsAzyS/nT2RvFJzQp7nFwC2JH8O7LMoinM1F5DcuMBfbODWALy63zajB6S1XpF8JPnVsLudtr/7aZpe+qwBYAdgbWPxJgLgwce5C+D9cD2Sxnc9kvchRHZ2kbIsb9r+Uzvy9dH1PsnbOjO+PnoFeWwiMXwsiwhc+dkSGLo+gDtXNtvQOGIQWQcc4NqqyYlorVeWzF5mhtxsVjSrrquVYxAZAxQiDpKRyKCU1pJKC8Bbw5VqQgKehEibNng784wjGpG25yRJzgB8eKi8OQkiaO5jpLT6ZMT02c0YoOiIg+hIZFAOu4PoiBIdmeSMiI4MBUUQHaQfiQzO8TOe0o/8gaW0ukb6kYGg6MiSGqsxQSFyahlBwKAzFtBzYKpGGHTGssqbSMigM5ah58BUoP4ZfgG1fPv2oHAfdQAAAABJRU5ErkJggg=="/>
      </defs>
    </svg>
  );
};

IconEventPlanning.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconEventPlanning.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconEventPlanning;
