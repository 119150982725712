import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from '../CategoryBar.module.css';

const IconMusic = props => {
  const { rootClassName, className, pencilClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" >
      <rect width="32" height="32" fill="url(#pattern0_2201_23663)"/>
      <defs>
        <pattern id="pattern0_2201_23663" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2201_23663" transform="scale(0.02)"/>
        </pattern>
        <image id="image0_2201_23663" width="50" height="50" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADEUlEQVR4nO1ZO2hUQRR9it+oaAyIWBgQEcFGXREFcQWzm3vOjJstXFFQG0FERS0ErVwLC4NVLGxUBC1sUghBCwttBAtFFAmKICr4C37jh/jDlZtMYFne6kuy7+1beQdetTNz77lzz8zcu56XIEGChkMqlZooIsuMMTtJngZwF8Ankoe9OCOXy80zxhQAdJG8AeAryVLlB+CNFxeQbBWRjSQ7AVwj2e/j9G+SDwFcILnPWrt6+Le6ON3R0TFLRNYYYw6R7AHw2i/SJF+634+KyIZ8Pt/iE4BoiORyuRnqNID9AM6T7HWRrXT6o0uf4+o0yblB1mfYREhu+ovT/S59Ol06tY7BTilUIgDeOiMDJG8COElym7V2cbFYHF8rO4xgRwYN6JEZmhEvQiK1XjeTyUwjuVZEDpDc3TBEfO6S734nnBcWRmPAGNNMUkgWSV4B8N7H6Z8AbpM8pUdz3Ymk0+kJJJcC2AXgHIAHficcgOcku0ke1GPcWts0vIaITI6ciN4Lej/o5eYuuQ8+0f6h0dY0ArC9vb19yUjt1BxlEb0I4FmVN9IjfW4A2AtgxWhOOEZFpOz77MTapeLNZDJzamnHCwtlUT+hKVLLS7AuRFTUoRnxGvhCrERCJCiSHRkhmGgkIJLUGgHy+XzLf5Faxph1DU+kUChMAnC9IYmk0+kp2qgguZXkndgWVsPIZrMLSR4heRXA4yoFlr7jnoZKZAwiHAfgGIBfVRp2AwBekLysBZmr38MjMloRktxRVmCd0UJMd8cY06wkq8wJh8hYREjylpu3Jch4a22TG//Ni5MISX5xT//pQcZba+c7O32xEqGrIkuFQmFqkPEissrZux8rEWoNr3O0rRpkPIfaRmrnbKxEqGu7ed3/akQYYxaQfKfjRWR9rETonBtML5L3AOyx1i7PZrOzlZiuC2CRa5f2ubS6VC2Y9RPhEJmVJJ9USeFSxdfT1tY2M1YiLIdG3xizWXNf1wDwSnfX/R3Xq70yEWHgnYhMhFEBYYswKpiwRRglTJgijBqpsESYIIHXcPgDqXcKGRQJOYkAAAAASUVORK5CYII="/>
      </defs>
    </svg>
  );
};

IconMusic.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconMusic.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconMusic;
